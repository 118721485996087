const FONT_NAME_TO_KEY_MAP = {
  font_0: 'Title',
  font_1: 'Menu',
  font_2: 'Page-title',
  font_3: 'Heading-XL',
  font_4: 'Heading-L',
  font_5: 'Heading-M',
  font_6: 'Heading-S',
  font_7: 'Body-L',
  font_8: 'Body-M',
  font_9: 'Body-S',
  font_10: 'Body-XS',
};

const PRIME_COLORS_REFERENCES = ['white/black', 'black/white', 'primery-1', 'primery-2', 'primery-3'];
const NON_PRIME_COLORS_PREFIX = 'color-';

export const getColorReferenceByColorName = (colorName) => {
  const editorIndex = Number(colorName.split('_').pop());
  if (editorIndex <= 5) {
    return PRIME_COLORS_REFERENCES[editorIndex - 1];
  } else if (editorIndex > 10) {
    return NON_PRIME_COLORS_PREFIX + (editorIndex - 10);
  }
};

export const normalizeColorThemeName = (styles) => {
  Object.keys(styles.colors).forEach((key) => {
    const color = styles.colors[key];
    if (color && color.themeName) {
      color.themeName = getColorReferenceByColorName(color.themeName);
    }
  });
  return styles;
};

export const getTextPresets = (allFonts) => {
  return Object.keys(allFonts).reduce((result, fontName) => {
    const { lineHeight, style, variant, weight, size, family, fontWithFallbacks } = allFonts[fontName];
    const fontKey = FONT_NAME_TO_KEY_MAP[fontName];
    if (fontKey) {
      result[fontKey] = {
        editorKey: fontName,
        lineHeight,
        style,
        weight,
        size,
        fontFamily: family,
        value: `font:${[style, variant, weight, `${size}/${lineHeight}`, fontWithFallbacks].join(' ')};`,
      };
    }
    return result;
  }, {});
};

export const getColorPresets = function (allColors) {
  return Object.keys(allColors)
    .map((name) => ({
      name,
      value: allColors[name],
      reference: getColorReferenceByColorName(name),
    }))
    .filter(({ reference }) => Boolean(reference));
};
